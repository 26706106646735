import dayjs from 'dayjs';
import { FundingOpportunity, FundingOpportunityStatus } from '@hum/types';
import { datadogLogs } from '@datadog/browser-logs';
/**
 * Returns the showcased opportunity from a list of funding opportunities.
 * The showcased opportunity is what should be displayed on the company dashboard.
 * @param fundingOpportunities: list of funding opportunities
 * @returns the showcased funding opportunity
 */
export default function getShowcasedOpportunity(
  fundingOpportunities: FundingOpportunity[]
): FundingOpportunity | null {
  const interestedOpportunities = fundingOpportunities.filter(
    (fundingOpportunity) =>
      fundingOpportunity.status === FundingOpportunityStatus.INTERESTED
  );
  const interestedOpportunity = interestedOpportunities[0];

  if (interestedOpportunities.length > 0) {
    if (interestedOpportunities.length > 1)
      datadogLogs.logger.error(
        'Company has multiple interested SmartRaise opportunities.'
      );
    if (interestedOpportunity)
      return getMostRecentOpportunity(interestedOpportunities);
  }

  const activeOpportunities = fundingOpportunities.filter(
    (fundingOpportunity) =>
      fundingOpportunity.status === FundingOpportunityStatus.ACTIVE
  );
  const activeOpportunity = activeOpportunities[0];

  if (activeOpportunities.length > 0) {
    if (activeOpportunities.length > 1)
      datadogLogs.logger.error(
        'Company has multiple active SmartRaise opportunities.'
      );

    if (activeOpportunity) return getMostRecentOpportunity(activeOpportunities);
  }

  const archivedOpportunities = fundingOpportunities.filter(
    (fundingOpportunity) =>
      fundingOpportunity.status === FundingOpportunityStatus.ARCHIVED
  );

  if (archivedOpportunities.length > 0)
    return getMostRecentOpportunity(archivedOpportunities); // replace with most recent archived opportunity

  return null;
}

function getMostRecentOpportunity(
  fundingOpportunities: FundingOpportunity[]
): FundingOpportunity | null {
  const sortedOpportunities = fundingOpportunities.sort(
    (a, b) =>
      dayjs().diff(dayjs(a.updatedAt)) - dayjs().diff(dayjs(b.updatedAt))
  );

  return sortedOpportunities[0];
}
