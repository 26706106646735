import { createMutation } from '@hum/api/src/utils/mutation';
import {
  PortfolioMonitoringInviteForm,
  PortfolioMonitoringStatusForm,
} from '@hum/types';

export const bulkInvestorInvites = createMutation<{
  companyId: number;
  fileId: number;
}>(
  ({ api, payload }) => {
    return api.post(
      `/companies/${payload.companyId}/investor/portfolio/invites/bulk`,
      {
        fileId: payload.fileId,
      }
    );
  },
  {
    errorToast: { message: 'Failed to assign correct file' },
    successToast: { message: 'File uploaded successfully' },
  }
);

export const createPortfolioInvite = createMutation<
  { message: string },
  PortfolioMonitoringInviteForm & { companyId: number }
>(
  ({ api, payload }) => {
    const { companyId, ...invite } = payload;
    return api.post(`/companies/${companyId}/investor/portfolio/invites`, {
      ...invite,
    });
  },
  {
    invalidates: ['/invites'],
    successToast: { message: 'Company added to queue' },
    errorToast: { message: 'Failed to create invite' },
  }
);

export const updatePortfolioInvite = createMutation<
  (PortfolioMonitoringInviteForm | PortfolioMonitoringStatusForm) & {
    companyId: number;
    inviteId: number;
  }
>(
  ({ api, payload }) => {
    const { companyId, inviteId, ...invite } = payload;
    return api.put(
      `/companies/${companyId}/investor/portfolio/invites/${inviteId}`,
      {
        ...invite,
      }
    );
  },
  {
    invalidates: ['/invites'],
    successToast: { message: 'Company successfully edited' },
    errorToast: { message: 'Failed to update invite' },
  }
);

export const bulkCompanyStatusUpdate = createMutation<{
  companyId: number;
  inviteIds: number[];
}>(
  ({ api, payload }) => {
    return api.put(
      `/companies/${payload.companyId}/investor/portfolio/invites/send`,
      {
        investorInvites: payload.inviteIds,
      }
    );
  },
  {
    successToast: { message: 'Company invites sent' },
    errorToast: { message: 'Failed to send invites' },
  }
);
