import { AppContext } from './AppContext';
import { createClient } from './client';
import { QueryFunction } from './query';

/**
 * This function spies on the api call within a queryFn and returns the
 * url that would be called. This is used to build the query key for
 * react-query.
 * @param queryFn: the query function that makes use of the api passed via context
 * @param context: the current context that would be passed to the queryFn if it were called
 */
export function getCacheKey<T>(
  queryFn: QueryFunction<T>,
  context: AppContext,
  scope?: string
): string[] {
  const key: string[] = scope ? [scope] : [];
  const spy = (url: string) => {
    if (url) key.push(url);
    throw new Error('abort');
  };
  const apiSpy = {
    ...createClient(),
    get: spy,
    post: spy,
    put: spy,
    delete: spy,
    patch: spy,
  };

  if (!!context.session) {
    try {
      //@ts-ignore
      queryFn({ ...context, api: apiSpy });
    } catch (e) {
      return key.length > 0 ? key : ['no-cache-key'];
    } finally {
      return key.length > 0 ? key : ['no-cache-key'];
    }
  } else return ['no-cache-key'];
}
