import { Query, QueryKey } from '@tanstack/react-query';
import { AppContext } from './AppContext';
import { getCacheKey } from './cache';
import { PredicateFunction } from './mutation';
import { QueryConfig } from './query';

export function invalidateQueries(
  invalidationList: (QueryConfig<any> | string | PredicateFunction)[],
  appContext: AppContext
) {
  const { queryClient } = appContext;

  for (const invalidation of invalidationList) {
    if (typeof invalidation === 'function') {
      queryClient.invalidateQueries({
        predicate: invalidation as (
          query: Query<unknown, unknown, unknown, QueryKey>
        ) => boolean,
      });
    } else if (typeof invalidation === 'string') {
      queryClient.invalidateQueries({
        predicate: (query) => {
          let invalidate = false;
          query.queryKey.forEach((key) => {
            if (typeof key === 'string' && key.includes(invalidation))
              invalidate = true;
          });
          return invalidate;
        },
        exact: false,
      });
    } else {
      const query = invalidation as QueryConfig<any>;
      const queryKey = getCacheKey(query.queryFn, appContext, query.scope);
      if (queryKey)
        queryClient.invalidateQueries({
          queryKey: queryKey,
          exact: true,
        });
    }
  }
}
