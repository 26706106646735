import { createMutation } from '../../utils/mutation';
import { SendTeaserEmailPayload } from '@hum/types';

export const sendTeaserEmail = createMutation<any, SendTeaserEmailPayload>(
  ({ api, payload, router, session }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;
    const path = `/companies/${companyId}/matches/${payload.matchUuid}`;
    return api.put(path, { syndication_investor_action_status: 'accepted' });
  },
  {
    successToast: { message: 'Teaser email successfully sent' },
  }
);
