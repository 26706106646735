import {
  FinancialDocuments,
  FinancialDocumentsParams,
  FinancialDocumentsTaskStatus,
  FinancialDocumentsTaskStatusParams,
} from '@hum/types';
import { createQuery } from '../../utils';

export const getFinancialDocuments = createQuery<
  { financialDocuments: FinancialDocuments[] },
  FinancialDocumentsParams
>(({ api, params }) =>
  api.get(`/v1/companies/${params.companyId}/financial_documents`)
);

export const getFinancialDocumentTaskStatus = createQuery<
  FinancialDocumentsTaskStatus,
  FinancialDocumentsTaskStatusParams
>(
  ({ api, params }) => {
    return api.get(
      `/v2/companies/${params.companyId}/analytics_download_tasks/${params.taskId}?doc_type=${params.docType}&connector_type=${params.connectorType}&connector_source=${params.connectorSource}`
    );
  },
  {
    errorToast: { message: 'Failed to fetch financial document task status' },
  }
);
