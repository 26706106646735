import { CompanyExtras } from '@hum/types';
import { createQuery } from '../../utils';
import { parseNAICS } from './helpers';

export const COMPANIES_EXTRA_PATH = '/extra';

export const getCompanyExtras = createQuery<CompanyExtras>(
  ({ api, router }) => {
    return api
      .get(`/v1/companies/${router.query.company_id}${COMPANIES_EXTRA_PATH}`)
      .then((data) => ({
        ...data,
        primaryNAICS: parseNAICS(data.naicsCode1, data.naicsName1),
        secondaryNAICS: parseNAICS(data.naicsCode2, data.naicsName2),
      }));
  }
);
