import { createQuery } from '../../utils';
import { FundingOpportunityList } from '@hum/types';
import { parseFundingOpportunityList } from './parseFundingOpportunityList';

export const getFundingOpportunities = createQuery<FundingOpportunityList>(
  ({ api, session, router }) => {
    const companyId = router.query.company_id || session.company.id;

    return api
      .get(`/v1/companies/${companyId}/funding_opportunities`)
      .then((res) => parseFundingOpportunityList(res.items || []));
  },
  {
    errorToast: { message: 'Failed to fetch funding opportunities' },
  }
);
