import { createMutation } from '@hum/api/src/utils/mutation';
import { CreateEvent } from '@hum/types';

export const createEvent = createMutation<CreateEvent>(
  ({ api, payload, session }) => {
    return api.post('/frontend/events', {
      ...payload,
      email: session.email,
    });
  }
);
