import {
  QueryConfig,
  client,
  getCacheKey,
  QueryConfigOptions,
} from '../../utils';
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { getSessionUser } from '../../domains/session/queries';
import { User } from '@hum/types';
import { omit } from 'lodash';
import { useRouter } from '..';
import { AppContext } from '../../utils/AppContext';
import { useEffect } from 'react';
import { toast } from '@hum/common/src/modules/toast';
import { useRefetchSuccess } from './useRefetchSuccess';

export type UseApiQueryOptions<R, P> = {
  params?: P; // extra fields to pass to the queryFn
  toastsEnabled?: boolean;
  onRefetchSuccess?: (data?: R, prevData?: R) => void;
} & Partial<UseQueryOptions<R>> &
  Partial<QueryConfigOptions>;

// Custom hook to wrap react query and inject api and session
export function useApiQuery<R, P = any>(
  query: QueryConfig<R, P>,
  hookOptions?: UseApiQueryOptions<R, P>
) {
  const options: UseApiQueryOptions<R, P> = {
    toastsEnabled: true,
    ...omit(query, 'queryFn'),
    ...hookOptions,
  };

  const queryClient = useQueryClient();
  const session = useQuery<User>(getSessionUser);
  const router = useRouter();
  const appContext = {
    api: client,
    session: session.data as User,
    params: options.params as P,
    router,
    queryClient,
  } as AppContext;

  const cacheKey = options.key
    ? [options.key]
    : getCacheKey(query.queryFn, appContext, options.scope || query.scope);
  const result = useQuery({
    queryKey: cacheKey,
    queryFn: (): Promise<R> => query.queryFn(appContext),
    enabled: (options?.enabled ?? true) && !!session.data && !!queryClient,
    ...omit(options, 'enabled'),
  });

  useEffect(() => {
    const { successToast, errorToast, toastsEnabled } = options;
    if (toastsEnabled) {
      if (result.isError && errorToast)
        toast.error(errorToast.message, errorToast.options);

      if (result.isSuccess && successToast)
        toast.success(successToast.message, successToast.options);
    }
  }, [result.isError, result.isSuccess]);

  useRefetchSuccess(result, options.onRefetchSuccess);

  return {
    ...result,
    isLoading: result.isLoading || session.isLoading,
    session,
    params: options.params,
    router,
    queryClient,
  };
}
