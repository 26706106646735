import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

/**
 * A hook to handle refetch success. Allows useApiQuery to expose a callback
 * that is called only when a refetch is successful as opposed to onSuccess which
 * is called both on initial load and refetch. When onRefetchSuccess is called,
 * the previous data is also passed to allow diffing of the refetch.
 * @param result - the result of a useQuery hook
 * @param onRefetchSuccess - a callback to be called when a refetch is successful
 */
export function useRefetchSuccess<R>(
  result: UseQueryResult<R>,
  onRefetchSuccess?: (data?: R, prevData?: R) => void
) {
  const { data, isRefetching, isSuccess } = result;
  const [previousData, setPreviousData] = useState<R | undefined>(undefined);

  useEffect(() => {
    if (isRefetching) {
      setPreviousData(data);
    }
  }, [isRefetching]);

  useEffect(() => {
    if (!isRefetching && isSuccess && onRefetchSuccess && !!previousData) {
      onRefetchSuccess(data, previousData);
      setPreviousData(undefined);
    }
  }, [!isRefetching, isSuccess, onRefetchSuccess, previousData]);
}
