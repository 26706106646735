import getShowcasedOpportunity from './getShowcasedOpportunity';
import {
  FundingOpportunity,
  FundingOpportunityList,
  FundingOpportunityStatus,
  Product,
} from '@hum/types';

/**
 *
 * @param items: raw list of funding opportunities
 * @returns Parsed list of funding opportunities with status booleans
 */
export function injectStatusBools(items: any[]): FundingOpportunity[] {
  return items.map((item) => ({
    ...item,
    ...getStatusBools(item.status),
  }));
}

/**
 *
 * @param status: funding opportunity status
 * @returns status booleans to make checks less verbose
 */
export function getStatusBools(status: FundingOpportunityStatus) {
  return {
    isArchived: status === FundingOpportunityStatus.ARCHIVED,
    isActive: status === FundingOpportunityStatus.ACTIVE,
    isInterested: status === FundingOpportunityStatus.INTERESTED,
  };
}

/**
 * Parses a raw list of funding opportunities into a FundingOpportunityList object
 * @param items: raw list of funding opportunities
 * @returns an opportunity list object with filters and calculated fields
 */
export function parseFundingOpportunityList(
  items: any[]
): FundingOpportunityList {
  const all = injectStatusBools(items);
  const showcased = getShowcasedOpportunity(all);

  return {
    all,
    showcased,
    smartRaise: all.filter((o) => o.product === Product.SMARTRAISE),
    smartMatch: all.filter((o) => o.product === Product.SMARTMATCH),
    smartMatchIsDisabled:
      showcased?.status === FundingOpportunityStatus.ACTIVE ||
      showcased?.status === FundingOpportunityStatus.INTERESTED,
  };
}
