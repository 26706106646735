import {
  CompanyContactStatus,
  CreditBoxReport,
  ReportType,
  SignUpV5Form,
  User,
} from '@hum/types';
import { createMutation } from '@hum/api/src/utils/mutation';

type AdminCreateUser = {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  companyPosition?: string;
  companyId: number;
};

type ChangeEmail = {
  currentPassword: string;
  newEmail: string;
};

type UpdateAccountSettings = {
  firstName: string;
  lastName: string;
  companyPosition: string;
  phone: string;
};

export type MNDAPayload = {
  companyId: number;
  firstName: string;
  lastName: string;
  role: string;
};

type AdminUpdateUser = AdminCreateUser & { userId: number };

export const createCreditBoxReports = createMutation<
  CreditBoxReport[],
  { companyId: number }
>(
  async ({ api, payload }) => {
    const reportTypes = Object.values(ReportType);
    const reportPromises = reportTypes.map(async (reportType) => {
      const report = await api.post(
        `/v1/companies/${payload.companyId}/reports/${reportType}`
      );
      return {
        id: report.id,
        companyId: report.companyId,
        createdAt: report.createdAt,
        type: report.reportType,
        status: report.status,
        rating: report.investorTestRating,
      };
    });

    const reports = await Promise.all(reportPromises);
    return reports;
  },
  {
    errorToast: {
      message: 'We couldn’t refresh the credit boxes.',
      options: {
        description: 'Please try again later.',
      },
    },
  }
);

export const createCompanyUser = createMutation<AdminCreateUser>(
  ({ api, payload }) => {
    return api.post('/users', payload);
  },
  {
    invalidates: ['/users'],
    successToast: { message: 'User invited successfully!' },
    errorToast: { message: 'Failed to invite user' },
  }
);

export const updateCompanyUser = createMutation<AdminUpdateUser>(
  ({ api, payload }) => {
    return api.patch(`/user/${payload.userId}`, {
      email: payload.email,
      firstName: payload.firstName,
      lastName: payload.lastName,
      phone: payload.phone,
      companyPosition: payload.companyPosition,
    });
  },
  {
    invalidates: ['/users'],
    successToast: { message: 'User updated successfully!' },
    errorToast: { message: 'Failed to update user' },
  }
);

export const createCommpanyFromAdmin = createMutation<SignUpV5Form>(
  ({ api, payload }) => {
    const { position, ...contact } = payload.contact || {};
    return api.post('/v5/admin_capital_applications', {
      contact: {
        ...contact,
        companyPosition: position,
      },
      company: {
        ...payload.company,
        website:
          payload?.company?.website?.startsWith('http://') ||
          payload?.company?.website?.startsWith('https://')
            ? payload?.company?.website.toLocaleLowerCase()
            : `https://${payload?.company?.website?.toLocaleLowerCase()}`,
      },
      application: {
        ...payload.application,
        lastRaised:
          payload.application?.lastRaised &&
          new Date(
            `${payload.application?.lastRaised
              .toString()
              .slice(
                0,
                2
              )}-01-${payload.application?.lastRaised.toString().slice(2)}`
          ).toISOString(),
        yearFounded: Number(payload.application?.yearFounded),
      },
    });
  },
  {
    successToast: { message: 'Company created successfully!' },
    errorToast: { message: 'An error occurred. Please try again.' },
  }
);

export const createMndaFile = createMutation<{ companyId: number }>(
  ({ api, payload }) => {
    return api.post(`/v5/companies/${payload.companyId}/mnda`);
  },
  {
    successToast: { message: 'MNDA signed!' },
    errorToast: { message: 'Failed to sign MNDA' },
  }
);

export const changeEmail = createMutation<{ message: string }, ChangeEmail>(
  ({ api, payload }) => {
    return api.post(`/users/change_email`, payload);
  },
  {
    errorToast: { message: 'Failed to change email' },
  }
);

export const updateAccountSettings = createMutation<UpdateAccountSettings>(
  ({ api, payload }) => {
    return api.patch(`/users/profile`, payload);
  },
  {
    invalidates: ['/session'],
    errorToast: { message: 'Failed to update account' },
    successToast: { message: 'Your changes were successfully saved' },
  }
);

export const acceptMNDA = createMutation<MNDAPayload>(
  ({ api, payload }) => {
    return api.post(`/companies/${payload.companyId}/onboarding/mnda`, {
      firstName: payload.firstName,
      lastName: payload.lastName,
      role: payload.role,
    });
  },
  {
    invalidates: ['/companies'],
    successToast: {
      message: 'We’ve emailed you a signed copy of the agreement',
    },
    errorToast: { message: 'Failed to accept MNDA' },
  }
);

export const editCompanyFormClosed = createMutation<{
  companyId: number | undefined;
  syndicationInvestor: any;
  company: any;
}>(
  ({ api, payload }) => {
    return api.patch(`/companies/${payload.companyId}/syndication/investors`, {
      syndicationInvestor: payload.syndicationInvestor,
      company: payload.company,
    });
  },
  {
    successToast: { message: 'Updated syndication investor successfully!' },
    errorToast: {
      message: 'Failed to update syndication investor information.',
    },
  }
);

export const resendInvite = createMutation<User>(
  ({ api, payload }) => {
    return api.post(`/otp`, {
      email: payload.email,
      action:
        payload.status === CompanyContactStatus.Invited
          ? 'SET_PASSWORD'
          : 'RESET_PASSWORD',
    });
  },
  {
    successToast: { message: 'Invite resent' },
    errorToast: { message: 'Failed to resend invite' },
  }
);

export const serviceStartDataPull = createMutation<{
  companyId: number;
  connectorId: number;
}>(
  ({ api, payload }) => {
    return api.post(
      `/companies/${payload.companyId}/connectors/${payload.connectorId}/connector_task`,
      { is_test: false }
    );
  },
  {
    successToast: { message: 'Data pull started!' },
    errorToast: { message: 'Failed to start data pull' },
  }
);

export const serviceStartDataPullAirbyte = createMutation<{
  companyId: number;
  connectorId: number;
}>(
  ({ api, payload }) => {
    return api.post(
      `/companies/${payload.companyId}/airbyte/connectors/${payload.connectorId}/connector_task`,
      { is_test: false }
    );
  },
  {
    successToast: { message: 'Data pull started!' },
    errorToast: { message: 'Failed to start data pull' },
  }
);

export const createPrincipalInvestment = createMutation<{ companyId: number }>(
  ({ api, payload }) => {
    return api.post(`/companies/${payload.companyId}/principal-investments`);
  },
  {
    invalidates: ['/principal-investments'],
    successToast: { message: 'Principal investment created!' },
    errorToast: { message: 'Failed to create principal investment' },
  }
);
