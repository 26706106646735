import { createMutation } from '@hum/api/src/utils/mutation';
import {
  Connector,
  CreateConnectUrl,
  ConnectUrlResponse,
  DetailedConnectorRequestForm,
  DetailedConnectorRequestFormFields,
  DeleteConnectorPayload,
  DETAILED_CONNECTOR_REQUEST_FORM_BASE_URL,
  FileOutput,
  CompanyFile,
  MutationFileInput,
} from '@hum/types';
import { getConnectors } from './queries';
import { createClient } from '@hum/api/src/utils';

export type CreateConnector = {
  connectorConfigId: number;
  parameters: Record<string, any>; // for capital connectors
};

export const createConnector = createMutation<Connector, CreateConnector>(
  ({ api, payload, router, session }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;
    return api.post(`/v1/companies/${companyId}/connectors`, {
      connector_config_id: payload.connectorConfigId,
      parameters: payload.parameters,
    });
  },
  {
    invalidates: [getConnectors],
  }
);

export const createConnectUrl = createMutation<
  ConnectUrlResponse,
  CreateConnectUrl
>(({ api, payload, router, session }) => {
  const companyId = session.isAdmin
    ? router.query.company_id
    : session.company.id;
  return api.post(`/v1/companies/${companyId}/connectors/connect_url`, {
    connector_config_id: payload.connectorConfigId,
    parameters: payload.parameters,
  });
});

export const disconnectConnector = createMutation<
  Connector,
  DeleteConnectorPayload
>(
  ({ api, payload, session, router }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;
    return api.post(
      `/v1/companies/${companyId}/connectors/${payload.connectorId}/disconnect`
    );
  },
  {
    invalidates: [getConnectors],
    errorToast: { message: 'Failed to disconnect connector' },
  }
);

export const deleteConnector = createMutation<
  Connector,
  DeleteConnectorPayload
>(
  ({ api, payload, session, router }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;
    return api.delete(
      `/v1/companies/${companyId}/connectors/${payload.connectorId}`
    );
  },
  {
    errorToast: { message: 'Failed to disconnect connector' },
    successToast: { message: 'Connector successfully disconnected' },
    invalidates: [getConnectors],
  }
);

export const submitDetailedConnectorRequestForm = createMutation<
  void,
  DetailedConnectorRequestForm
>(({ payload, session }) => {
  const client = createClient(DETAILED_CONNECTOR_REQUEST_FORM_BASE_URL);
  const body = new FormData();
  body.append(
    DetailedConnectorRequestFormFields.Environment,
    payload.environment || ''
  );
  body.append(DetailedConnectorRequestFormFields.Email, session.email || '');
  body.append(DetailedConnectorRequestFormFields.Reason, payload.reason || '');
  if (payload.reason === 'Other') {
    body.append(
      DetailedConnectorRequestFormFields.Connector,
      '__other_option__'
    );
    body.append(
      DetailedConnectorRequestFormFields.Other,
      payload.connector || ''
    );
  } else {
    body.append(
      DetailedConnectorRequestFormFields.Connector,
      payload.connector || ''
    );
  }

  return client.post('/formResponse', body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
});

export const uploadFile = createMutation<CompanyFile | void, MutationFileInput>(
  async ({ api, session, router, payload }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;

    const { blob, ...body } = payload;

    if (!blob) {
      return;
    }

    // post file meta data and get pre-signed url for posting to s3
    const { file, presignedPost }: FileOutput = await api.post(
      `/companies/${companyId}/files`,
      body
    );
    const { fields, url } = presignedPost;
    const form = new FormData();

    // we don't want these in camelCase because the request will fail
    const correctedFields = {
      awsAccessKeyId: 'AWSAccessKeyId',
      contentType: 'Content-Type',
      xAmzSecurityToken: 'x-amz-security-token',
    };

    Object.entries(fields).forEach(([key, value]) => {
      form.append(correctedFields[key] || key, value);
    });
    form.append('file', blob);

    // post file contes to s3
    const client = createClient(url, { withCredentials: false });
    try {
      client.post('', form);
    } catch (e) {
      console.error(e);
    } finally {
      return file;
    }
  }
);

export const deleteFile = createMutation<void, { fileId: number }>(
  async ({ api, session, router, payload }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;

    return api.delete(`/companies/${companyId}/files/${payload.fileId}`);
  }
);
