import { client } from '../../utils';

// Unique query definition, this is the only query
// that uses react query directly and does not
// get wrapped with createQuery. All other queries
// are wrapped with createQuery and are passed the session user object.
export const getSessionUser = {
  queryKey: ['/session'],
  queryFn: () => {
    return client
      .get(`/session`, {
        withCredentials: true,
      })
      .then(({ user }) => {
        if (user.roles.length === 0) {
          throw new Error(
            `User ${user.id} ${user.email} does not have any roles set`
          );
        }

        return { ...user, isAdmin: user.roles.includes('admin') };
      });
  },
};
