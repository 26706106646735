import { createQuery } from '../../utils';

export type NoticesParams = {
  companyId?: number;
  noticeName?: string;
};
export const getNoticesWasSeen = createQuery<boolean, NoticesParams>(
  ({ api, params }) => {
    return api.get(
      `/companies/${params.companyId}/notices/${params.noticeName}`,
      {
        headers: { Pragma: 'no-cache' },
      }
    );
  }
);
