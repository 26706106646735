import { createMutation } from '../../utils/mutation';
import { FundingOpportunity, FundingOpportunityStatus } from '@hum/types';
import { getFundingOpportunities } from './queries';

export const expressOpportunityInterest = createMutation<FundingOpportunity>(
  ({ api, payload, router, session }) => {
    const companyId = session.isAdmin
      ? router.query.company_id
      : session.company.id;
    return api.patch(
      `/v1/companies/${companyId}/funding_opportunities/${payload.id}`,
      {
        status: FundingOpportunityStatus.INTERESTED,
      }
    );
  },
  {
    errorToast: { message: 'Failed to mark opportunity as accepted' },
    successToast: { message: 'Opportunity successfully marked as accepted' },
    invalidates: [getFundingOpportunities],
  }
);

// Admin only
export const createOpportunity = createMutation<FundingOpportunity>(
  ({ api, payload, router }) => {
    return api.post(
      `/v1/companies/${router.query.company_id}/funding_opportunities`,
      {
        reportId: payload.reportId,
      }
    );
  },
  {
    errorToast: { message: 'Failed to create funding opportunity' },
    successToast: { message: 'Opportunity successfully created' },
    invalidates: [getFundingOpportunities],
  }
);

// Admin only
export const archiveOpportunity = createMutation<FundingOpportunity>(
  ({ api, payload, router }) => {
    return api.patch(
      `/v1/companies/${router.query.company_id}/funding_opportunities/${payload.id}`,
      {
        status: FundingOpportunityStatus.ARCHIVED,
        archiveReason: payload.archiveReason,
        archiveReasonText: payload.archiveReasonText,
      }
    );
  },
  {
    errorToast: { message: 'Failed to archive opportunity' },
    successToast: { message: 'Opportunity archived successfully' },
    invalidates: [getFundingOpportunities],
  }
);

// Admin only
export const unarchiveOpportunity = createMutation<FundingOpportunity>(
  ({ api, payload, router }) => {
    return api.patch(
      `/v1/companies/${router.query.company_id}/funding_opportunities/${payload.id}`,
      {
        status: FundingOpportunityStatus.ACTIVE,
      }
    );
  },
  {
    errorToast: { message: 'Failed to unarchive opportunity' },
    successToast: { message: 'Opportunity successfully unarchived' },
    invalidates: [getFundingOpportunities],
  }
);

//Admin only
export const resendOpportunityEmail = createMutation<FundingOpportunity>(
  ({ api, payload, router }) => {
    return api.post(
      `/v1/companies/${router.query.company_id}/funding_opportunities/${payload.id}/send_email`
    );
  },
  {
    errorToast: { message: 'Failed to resend email' },
    successToast: { message: 'Email resent' },
  }
);

// Admin only
export const refreshFinancialHighlights = createMutation<FundingOpportunity>(
  ({ api, payload, router }) => {
    return api.post(
      `/v1/companies/${router.query.company_id}/funding_opportunities/${payload.id}/refresh_highlights`
    );
  },
  {
    errorToast: { message: 'Failed to refresh' },
    successToast: { message: 'Financial highlights refreshed' },
  }
);
