import { createEvent } from '../../domains';
import { CreateEvent } from '@hum/types';
import { useApiMutation } from '..';
import { analytics } from '@hum/common';

export const useAnalyticsEvent = () => {
  const { mutate } = useApiMutation<CreateEvent>(createEvent);

  return (
    name: string,
    data: Record<string, any> = {},
    includeRoleScope: boolean = false
  ) => {
    console.debug(`@track▸${name}`);
    analytics.debugAddTracked(name, data);
    mutate({ type: name, payload: data, includeRoleScope });
  };
};
