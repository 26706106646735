/**
 * HTTP client factory.
 */

import axios from 'axios';
import mergeConfig from 'axios/lib/core/mergeConfig';

const create = axios.create.bind(axios);
const httpClient = axios;

/**
 * Override axios.create method to extend instances.
 */
httpClient.create = (config) => {
  const instance: any = create(config);

  /**
   * Extend axios instance with capability of creating derived instances.
   */
  instance.create = function (instanceConfig: any) {
    const newInstance = httpClient.create(
      mergeConfig(this.defaults, instanceConfig)
    );

    // copy over current interceptors
    for (const hook of ['request', 'response']) {
      // @ts-ignore not typed :(
      this.interceptors[hook].forEach(({ fulfilled, rejected }) => {
        newInstance.interceptors[hook].use(fulfilled, rejected);
      });
    }

    return newInstance;
  };

  return instance;
};

export { httpClient, axios };
