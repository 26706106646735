import { User } from '@hum/types';
import { createQuery } from '../../utils';

export const getIsInvitationRedeemed = createQuery<
  { isRedeemed: boolean },
  { invitationHash: string }
>(({ api, params }) => {
  return api.get(
    `/investor/portfolio/invites/${params.invitationHash}/is-redeemed`
  );
});

export const userReviewSession = createQuery<any, User>(({ api }) => {
  return api.get(`/session`);
});

export const resendVerifyEmail = createQuery<{}>(
  ({ api }) => {
    return api.get(`/users/resend_verify_email`);
  },
  {
    errorToast: {
      message:
        'There was an error when trying to resend your verification email',
    },
  }
);
