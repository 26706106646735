import { createMutation } from '@hum/api/src/utils/mutation';
import {
  CreateBulkDownload,
  CreateBulkDownloadParams,
  BulkDownloadSelectedFileParams,
} from '@hum/types';

export const createBulkDownload = createMutation<
  CreateBulkDownload,
  CreateBulkDownloadParams
>(
  ({ api, payload }) => {
    return api.post(`/companies/${payload.companyId}/files/bulk_download`);
  },
  {
    errorToast: { message: 'Failed to download' },
  }
);

export const createBulkDownloadFromSelection = createMutation<
  CreateBulkDownload,
  BulkDownloadSelectedFileParams
>(
  ({ api, session, router, payload }) => {
    const companyId = router.query.company_id || session.company.id;
    return api.post(`/v1/companies/${companyId}/files/bulk_download`, payload);
  },
  {
    errorToast: { message: 'Failed to download selected files' },
  }
);
