import { useHistory } from 'react-router-dom';
import * as qs from 'query-string';
import { useCallback, useMemo } from 'react';
import { Router, RouterQuery, Url } from '@hum/types';

export const useRouter = (): Router => {
  const history = useHistory();
  const query = (useMemo(() => {
    return qs.parse(history.location.search);
  }, [history.location.search]) as any) as RouterQuery;

  return {
    asPath: history.location.pathname + history.location.search,
    pathname: history.location.pathname,
    query,
    updateQuery: useCallback(
      (properties: Record<string, string | number>, replace = false) => {
        const newQuery: any = replace
          ? properties
          : { ...query, ...properties };
        history.push(history.location.pathname + '?' + qs.stringify(newQuery));
      },
      [query, history.location]
    ),
    push(url: Url) {
      history.push(url.toString());
    },
  };
};
