import {
  PorfolioMonitoringInviteStatus,
  PortfolioMonitoringCompanyInviteUrl,
  PortfolioMonitoringConnectorStatus,
  PortfolioMonitoringDashboard,
  PortfolioMonitoringInvite,
} from '@hum/types';
import { createQuery } from '../../utils';

export const getPortfolioInvites = createQuery<
  PortfolioMonitoringInvite[],
  { companyId: number }
>(({ api, params }) => {
  return api
    .get(`/companies/${params.companyId}/investor/portfolio/invites`)
    .then((invites) => {
      return [
        ...invites.filter(
          ({ status }) => status === PorfolioMonitoringInviteStatus.Created
        ),
        ...invites.filter(
          ({ status }) => status === PorfolioMonitoringInviteStatus.Pending
        ),
        ...invites.filter(
          ({ status }) => status === PorfolioMonitoringInviteStatus.Sent
        ),
        ...invites.filter(
          ({ status }) => status === PorfolioMonitoringInviteStatus.Viewed
        ),
        ...invites.filter(
          ({ status }) => status === PorfolioMonitoringInviteStatus.Accepted
        ),
      ];
    });
});

export const getPortfolioDashboard = createQuery<
  PortfolioMonitoringDashboard,
  { companyIds: number[] }
>(
  ({ api, params }) => {
    const companyIdParams = new URLSearchParams();
    params.companyIds.forEach((companyId) => {
      companyIdParams.append('company_id', companyId.toString());
    });
    return api.get(`/companies/kpis`, {
      params: companyIdParams,
    });
  },
  {
    errorToast: { message: 'Failed to fetch dashboard' },
  }
);

export const getPMConnectorStatus = createQuery<
  PortfolioMonitoringConnectorStatus[],
  { companyId: number }
>(
  ({ api, params }) => {
    return api.get(`/companies/${params.companyId}/portfolio-connectors`);
  },
  {
    errorToast: { message: 'Failed to fetch connectors status' },
  }
);

export const getPortfolioCompanyInviteUrl = createQuery<
  PortfolioMonitoringCompanyInviteUrl,
  { companyId: number }
>(
  ({ api, params }) => {
    return api.get(`/investor/portfolio/${params.companyId}/invite-url`);
  },
  {
    errorToast: { message: 'Failed to fetch invite url' },
  }
);
