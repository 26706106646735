import { createMutation } from '@hum/api/src/utils/mutation';
import { Login, OTPAction, PasswordReset, SignupV5, User } from '@hum/types';

export const recoverAccount = createMutation<Pick<Login, 'email'>>(
  ({ api, payload }) => {
    return api.post(`/otp`, {
      email: payload.email,
      action: OTPAction.ResetPassword,
    });
  },
  {
    waitForSession: false,
    successToast: {
      message: 'An email to your inbox has been sent for password reset',
    },
    errorToast: {
      message: 'There was an error when trying to recover your account',
    },
  }
);

export const setPassword = createMutation<
  Pick<PasswordReset, 'password' | 'acceptedCommunications'> & { token: string }
>(
  ({ api, payload }) => {
    return api.patch(`/users/password`, payload, {
      headers: { 'X-OTP': payload.token },
    });
  },
  {
    waitForSession: false,
    errorToast: {
      message: 'There was an error when trying to recover your account',
    },
  }
);

export const setPasswordWithTos = createMutation<
  PasswordReset & { token: string }
>(
  ({ api, payload }) => {
    return api.patch(`/users/password`, payload, {
      headers: { 'X-OTP': payload.token },
    });
  },
  {
    waitForSession: false,
    errorToast: {
      message: 'There was an error when trying to recover your account',
    },
  }
);

export const login = createMutation<{ user: User }, Login>(
  ({ api, payload }) => {
    return api.post(`/session`, payload);
  },
  {
    waitForSession: false,
    errorToast: {
      message: 'There was an error when trying to login',
    },
  }
);

export const userLogout = createMutation<{}>(
  ({ api }) => {
    return api.delete(`/session`).then(() => ({
      success: true,
    }));
  },
  {
    errorToast: { message: 'Failed to logout' },
  }
);

export const verifyEmail = createMutation<{ token: string }>(
  ({ api, payload }) => {
    return api.post(`/users/verify-email`, undefined, {
      headers: { 'X-OTP': payload.token },
    });
  },
  {
    waitForSession: false,
    errorToast: {
      message: 'There was an error when trying to recover your account',
    },
  }
);

export const createSignupV5 = createMutation<SignupV5>(
  ({ api, payload }) => {
    const {
      workEmail,
      utmMedium,
      utmSource,
      utmCampaign,
      utmTerm,
      utmContent,
      companyRole,
      ...rest
    } = payload;
    return api.post(`/v1/signup`, {
      ...rest,
      email: workEmail,
      companyPosition: companyRole,
      utmParams: {
        utmMedium: utmMedium,
        utmSource: utmSource,
        utmCampaign: utmCampaign,
        utmTerm: utmTerm,
        utmContent: utmContent,
      },
    });
  },
  {
    waitForSession: false,
  }
);
