import { ToastBody } from '@hum/common/src/modules/toast';
import { AppContext } from './AppContext';

export type QueryFunction<R, P = any> = (context: AppContext<P>) => Promise<R>;

export type QueryConfig<R, P = any> = {
  queryFn: QueryFunction<R, P>;
} & QueryConfigOptions;

export type QueryConfigOptions = {
  scope?: string; // prefix for the query key
  key?: string; // key for the query key
  errorToast?: ToastBody; // message for a toast on error
  successToast?: ToastBody; // message for a toast on success
};

export function createQuery<R, P = any>(
  queryFn: QueryFunction<R, P>,
  options: QueryConfigOptions = {}
): QueryConfig<R, P> {
  return {
    ...options,
    queryFn,
  };
}
