import { createMutation } from '@hum/api/src/utils/mutation';
import { FinancialDocumentsTaskParams } from '@hum/types';
import { FinancialDocumentsTask } from '@hum/types';

export const createFinancialDocumentDownloadTask = createMutation<
  FinancialDocumentsTask,
  FinancialDocumentsTaskParams
>(
  ({ api, payload }) => {
    return api.post(
      `/v2/companies/${payload.companyId}/analytics_download_tasks`,
      {
        doc_type: payload.docType,
        connector_type: payload.connectorType,
        connector_source: payload.connectorSource,
      }
    );
  },
  {
    errorToast: { message: 'Failed to download' },
  }
);
