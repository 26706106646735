import { createMutation } from '@hum/api/src/utils/mutation';
import { NoticesParams } from './queries';

export const setNoticeAsSeen = createMutation<NoticesParams>(
  ({ api, payload }) => {
    return api.post(`/companies/${payload.companyId}/notices`, {
      name: payload.noticeName,
    });
  }
);
