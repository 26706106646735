import type { Query, QueryKey } from '@tanstack/react-query';
import { ToastBody } from '@hum/common/src/modules/toast';
import { AppContext } from './AppContext';
import { QueryConfig } from './query';

export type PredicateFunction =
  | ((query: Query<unknown, Error, unknown, QueryKey>) => boolean)
  | undefined;

export type MutationContext<TPayload, TParams> = {
  payload: Partial<TPayload>;
} & AppContext<TParams>;

export type MutationFunction<TReturn, TPayload, TParams> = (
  context: MutationContext<TPayload, TParams>
) => Promise<TReturn>;

export type MutationConfig<TReturn, TPayload, TParams = any> = {
  mutationFn: MutationFunction<TReturn, TPayload, TParams>;
} & MutationConfigOptions;

export type MutationConfigOptions = {
  errorToast?: ToastBody; // message for a toast on error
  successToast?: ToastBody; // message for a toast on success
  invalidates?: (QueryConfig<any> | string | PredicateFunction)[]; // list of queryKeys / matchers to invalidate on success
  waitForSession?: boolean; // should the query wait until session is available
};

export function createMutation<TReturn, TPayload = TReturn, TParams = any>(
  mutationFn: MutationFunction<TReturn, TPayload, TParams>,
  options: MutationConfigOptions = {}
): MutationConfig<TReturn, TPayload> {
  return {
    ...{
      ...options,
      waitForSession:
        options.waitForSession === undefined ? true : options.waitForSession,
    },
    mutationFn,
  };
}
