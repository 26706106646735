import {
  CompanyAnalysis,
  CompanyAnalysisWarning,
  CompanyChart,
  CompanyContact,
  CompanyContactStatus,
  CompanyDataAsset,
  CreditBoxReport,
  ReportType,
  User,
  PrincipalInvestment,
  Company,
} from '@hum/types';
import { createQuery } from '../../utils';

type CompanyIdParam = {
  companyId: number;
};

type ChartsByTabNameParams = {
  companyId: number;
  tabName: string;
  companyAnalysisId?: number;
};

type CompanyAnalysisParams = {
  companyId: number;
  companyAnalysisId?: number;
};

export const getCreditBoxReports = createQuery<
  CreditBoxReport[],
  CompanyIdParam
>(
  async ({ api, params }) => {
    const reportTypes = Object.values(ReportType);

    const reportPromises = reportTypes.map(async (reportType) => {
      const response = await api.get(
        `/v1/companies/${params.companyId}/reports?report_type=${reportType}`
      );
      return response?.items.map(
        (item) =>
          ({
            id: item.id,
            companyId: item.companyId,
            createdAt: item.createdAt,
            type: item.reportType,
            status: item.status,
            rating: item.investorTestRating,
          } as CreditBoxReport)
      );
    });

    const reports = await Promise.all(reportPromises);
    return reports.flat();
  },
  {
    key: 'getCreditBoxReports',
    errorToast: { message: 'Failed to fetch credit box reports' },
  }
);

export const getCompany = createQuery<Company, CompanyIdParam>(
  ({ api, params }) => {
    return api.get(`/companies/${params.companyId}`).then((company) => {
      const { capitalApplication, ...rest } = company;
      return {
        ...rest,
        acceptedToS: company.acceptedTos,
        acceptedToSOn: company.acceptedTosOn,
        acceptedFeeToSOn: company.acceptedFeeTosOn,
        acceptedNDA: company.acceptedNda,
        acceptedNDAOn: company.acceptedNdaOn,
        application: capitalApplication,
      };
    });
  }
);

export const getCompanyUsers = createQuery<User[], CompanyIdParam>(
  ({ api, params }) => {
    return api.get(`/companies/${params.companyId}/users`).then((response) => {
      return [
        ...response.filter(
          ({ status }) => status === CompanyContactStatus.Active
        ),
        ...response.filter(
          ({ status }) => status === CompanyContactStatus.Invited
        ),
        ...response.filter(
          ({ status }) => status === CompanyContactStatus.Disabled
        ),
      ];
    });
  }
);

export const getCompanyContact = createQuery<CompanyContact, CompanyIdParam>(
  ({ api, params }) => {
    return api.get(`/companies/${params.companyId}/contact`);
  }
);

export const getChartsByTabName = createQuery<
  CompanyChart[],
  ChartsByTabNameParams
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/charts/${params.tabName}`);
});

export const getCompanyAnalyses = createQuery<
  CompanyAnalysis[],
  CompanyIdParam
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/company_analysis`);
});

export const getCompanyAnalysisWarnings = createQuery<
  CompanyAnalysisWarning[],
  CompanyAnalysisParams
>(({ api, params }) => {
  return api.get(
    `/companies/${params.companyId}/company_analysis/${params.companyAnalysisId}/company_analysis_warnings`
  );
});

export const getCompanyFinancialSummary = createQuery<
  { status: boolean },
  CompanyIdParam
>(({ api, params }) => {
  return api.get(
    `/companies/${params.companyId}/analytics_permissions?status=financial_summary`
  );
});

export const getSyndicationInsightsCharts = createQuery<
  CompanyChart[],
  CompanyAnalysisParams
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/insights/charts`);
});

export const getCompanyDataAssets = createQuery<
  CompanyDataAsset[],
  CompanyIdParam
>(({ api, params }) => {
  return api.get(`/companies/${params.companyId}/data_assets`);
});

export const getPrincipalInvestments = createQuery<
  PrincipalInvestment[],
  CompanyIdParam
>(({ api, params }) => {
  return api
    .get(`/companies/${params.companyId}/principal-investments`)
    .then((response) => {
      const { balanceSheetPartners, ...rest } = response;
      return {
        ...rest,
        interestedBalanceSheetPartners: balanceSheetPartners,
      };
    });
});
