import {
  BulkDownloadTaskStatus,
  BulkDownloadFileList,
  GetBulkDownloadTaskStatusParams,
  GetBulkDownloadFileListParams,
} from '@hum/types';
import { createQuery } from '../../utils';

export const getBulkDownloadTaskStatus = createQuery<
  BulkDownloadTaskStatus,
  GetBulkDownloadTaskStatusParams
>(
  ({ api, params = {} }) => {
    return api.get(
      `/companies/${params.companyId}/files/bulk_download/${params.taskId}`
    );
  },
  {
    errorToast: { message: 'Failed to fetch bulk download task status' },
  }
);

export const getBulkDownloadFileList = createQuery<
  BulkDownloadFileList,
  GetBulkDownloadFileListParams
>(({ api, params }) => {
  return api.get(`/v1/companies/${params.companyId}/files/bulk_download`);
});
